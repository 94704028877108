import Baloon from "../../images/elements/london/baloon.png";
import BaloonWebp from "../../images/elements/london/baloon.webp";
import City from "../../images/elements/london/city.png";
import CityWebp from "../../images/elements/london/city.webp";
import Hand from "../../images/elements/london/hand.png";
import HandWebp from "../../images/elements/london/hand.webp";
import Shape1 from "../../images/elements/london/shape1.svg";
import Shape2 from "../../images/elements/london/shape2.svg";
import Bubbles from "../../images/elements/london/bubbles.svg";
import Sky from "../../images/elements/common/sky.png";
import SkyWebp from "../../images/elements/common/sky.webp";
import Collage from "../Collage";

import * as React from "react";

const LondonCollage = ({ className }) => (
    <Collage
        className={className}
        fullWidth
        zIndex={0}
        minWidth={1000}
        items={[
            {
                src: Sky,
                srcWebp: SkyWebp,
                alt: "Sky",
                height: "100%",
                width: "100%",
                cover: true,
                top: 0,
            },
            {
                src: City,
                srcWebp: CityWebp,
                alt: "City of London",
                root: true,
                height: "auto",
                width: "100%",
            },
            {
                src: Hand,
                srcWebp: HandWebp,
                alt: "Hand holding a phone with Sherlock Holmes",
                height: "77%",
                width: "auto",
                bottom: 0,
                left: 13.4,
                leftXs: 32,
            },
            {
                src: Baloon,
                srcWebp: BaloonWebp,
                alt: "Hot air balloon",
                height: "19.2%",
                width: "auto",
                bottom: 46,
                bottomXs: 65,
                right: 22.7,
                rightXs: 32.7,
                animation: [0.1, -1],
            },
            {
                src: Shape2,
                alt: "Shape 2",
                height: "1.71%",
                width: "auto",
                bottom: 32.1,
                right: 16.59,
                rightXs: 32,
            },
            {
                src: Bubbles,
                alt: "Bubbles",
                height: "30.7%",
                width: "auto",
                bottom: 46.14,
                left: 30.2,
                leftXs: 42,
            },
            {
                src: Shape1,
                alt: "Shape 1",
                height: "4.2%",
                width: "auto",
                bottom: 55.3,
                left: 8.47,
            },
        ]}
    />
);

export default LondonCollage;
