// https://github.com/twitter/twemoji/blob/gh-pages/2/twemoji.js#L232
const UFE0Fg = /\uFE0F/g;

// \u200D is a zero-width joiner character
// https://github.com/twitter/twemoji/blob/gh-pages/2/twemoji.js#L235
const U200D = String.fromCharCode(0x200d);

export function unicodeToCodepoint(input) {
    return toCodePoint(
        input.indexOf(U200D) < 0 ? input.replace(UFE0Fg, "") : input
    );
}

function toCodePoint(input, separator = "-") {
    const codePoints = [];
    // eslint-disable-next-line
    for (const codePoint of input) {
        codePoints.push(codePoint.codePointAt(0).toString(16));
    }
    return codePoints.join(separator);
}
