import * as styles from "./Collage.module.css";

import React, { useRef, useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import isUndefined from "lodash/isUndefined";
import isFinite from "lodash/isFinite";
import isArray from "lodash/isArray";
import isPlainObject from "lodash/isPlainObject";

// TODO: option to turn off animation (for performance)

const SCREEN_WIDTH_XS = 700;
const MEDIA_SCREEN_XS = `(max-width: ${SCREEN_WIDTH_XS}px)`;

export default function Collage({
	items,
	className,
	fullWidth,
	minWidth,
	maxWidth,
	zIndex,
	animate,
}) {
	const root = useRef();

	const sizeStyle = fullWidth
		? { width: "100%", minWidth, height: "auto" }
		: { height: "auto", width: "max-content", maxWidth };
	const style = { ...sizeStyle, zIndex };

	const [viewSize, setViewSize] = useState([null, null, null]);
	const [viewWidth, viewHeight, windowWidth] = viewSize;
	const [visibleFrac, setVisibleFrac] = useState(0);

	const resizeHandler = useCallback(() => {
		setViewSize([
			root.current.clientWidth,
			root.current.clientHeight,
			window.innerWidth,
		]);
	}, []);

	useEffect(() => {
		resizeHandler();
		window.addEventListener("resize", resizeHandler);
		return () => window.removeEventListener("resize", resizeHandler);
	}, [resizeHandler]);

	const scrollHandler = useCallback(() => {
		setVisibleFrac(elementPercentInView(root.current));
	}, []);

	useEffect(() => {
		scrollHandler();
		window.addEventListener("scroll", scrollHandler);
		return () => window.removeEventListener("scroll", scrollHandler);
	}, [scrollHandler]);

	const handleLoad = useCallback(() => {
		resizeHandler();
		scrollHandler();
	}, [resizeHandler, scrollHandler]);

	const filteredItems = items.filter(({ minViewWidth, minViewHeight }) => {
		if (minViewWidth && viewWidth < minViewWidth) {
			return false;
		}
		if (minViewHeight && viewHeight < minViewHeight) {
			return false;
		}
		return true;
	});

	return (
		<div
			className={classnames(styles.collage, className)}
			style={style}
			ref={root}
		>
			{filteredItems.map((x, i) => (
				<Item
					item={x}
					key={i}
					index={i}
					transitionFrac={visibleFrac}
					viewWidth={viewWidth}
					viewHeight={viewHeight}
					windowWidth={windowWidth}
					animate={animate}
					onLoad={handleLoad}
				/>
			))}
		</div>
	);
}
Collage.defaultProps = {
	animate: true,
};

function Item({
	item,
	index,
	transitionFrac,
	viewWidth,
	viewHeight,
	windowWidth,
	animate,
	onLoad,
}) {
	const {
		src,
		srcWebp,
		srcXs,
		srcXsWebp,
		width,
		height,
		minWidth,
		minHeight,
		root,
		cover,
		alt,
		animation,
	} = item;

	const style = {
		width,
		height,
		minWidth,
		minHeight,
		top: getPos(item, "top", windowWidth),
		bottom: getPos(item, "bottom", windowWidth),
		left: getPos(item, "left", windowWidth),
		right: getPos(item, "right", windowWidth),
		objectFit: cover ? "cover" : "fill",
		...calcTransition(
			animate,
			item,
			index,
			viewWidth,
			viewHeight,
			transitionFrac || 0,
			animation
		),
	};

	const className = classnames(styles.item, root ? styles.root : null);

	if (typeof src === "string") {
		return (
			<picture>
				{srcXsWebp ? (
					<source
						srcSet={srcXsWebp}
						media={MEDIA_SCREEN_XS}
						type="image/webp"
					/>
				) : null}
				{srcWebp ? <source srcSet={srcWebp} type="image/webp" /> : null}
				{srcXs ? (
					<source srcSet={srcXs} media={MEDIA_SCREEN_XS} />
				) : null}
				<img
					src={src}
					className={className}
					style={style}
					alt={alt || ""}
					onLoad={onLoad}
				/>
			</picture>
		);
	}

	if (typeof src === "number") {
		style.height = 0;
		style.paddingTop = `${src * 100}%`;
		return <div className={className} style={style} />;
	}

	if (typeof src === "function") {
		return src({ className, style, onLoad });
	}
}

function getPos(item, direction, windowWidth) {
	let pos = item[direction];

	if (!isFinite(pos)) {
		return undefined;
	}

	const posXs = item[`${direction}Xs`];

	if (windowWidth && windowWidth <= SCREEN_WIDTH_XS && isFinite(posXs)) {
		pos = posXs;
	}

	return `${pos}%`;
}

const ANIMATION_COEFFS_X = [
	// 0..1
	0.35449385516193355, 0.6498864390476031, 0.7350697842734185,
	0.6695575949070189, 0.1457441378622666, 0.22367503177913228,
	0.28320871514213697, 0.40816947989782204, 0.675441906855499,
	0.013113083411521176, 0.27357496136124104, 0.7376170594882075,
	0.7596306960913668, 0.8885602303522265, 0.28293948117207246,
	0.8629102414371385, 0.8979971820334206, 0.3272812534386689,
	0.7608184285374863, 0.9161448979345196,
];

const ANIMATION_COEFFS_Y = [
	// -1..1
	0.9620178654806251, -0.5199150426764725, -0.8762440530266968,
	-0.9640410185891382, 0.5009533833472988, 0.0956066356232188,
	-0.06126791401264553, 0.7373955675072097, -0.6067700165092429,
	0.26392300207994746, 0.6463492141445797, -0.6370168444607449,
	0.1251901471385226, 0.07947791423772421, -0.2166577253370794,
	-0.6788402202001351, 0.5304330027845332, 0.18244226380286444,
	0.987945479640671, -0.8399481202908712,
];

function calcTransition(
	animate,
	item,
	index,
	viewWidth,
	viewHeight,
	frac,
	animation
) {
	const { root } = item;
	if (root || !animate) {
		return null;
	}

	let translateX;
	let translateY;

	if (typeof animation === "function") {
		[translateX, translateY] = animation(viewWidth, viewHeight, frac);
	}

	const endCoef = isPlainObject(animation) ? animation.end || 0.2 : 0.2;
	const time = Math.max(0, (1 - frac) * (1 + endCoef) - endCoef);
	const [coefX, coefY] = getAnimationCoefficients(index, item, animation);
	translateX = viewWidth * coefX * time;
	translateY = viewHeight * coefY * time;

	if (translateX === 0 && translateY === 0) {
		return null;
	}

	return {
		transform: `translate(${translateX}px, ${translateY}px)`,
	};
}

function getAnimationCoefficients(index, item, animation) {
	const { bottom, left } = item;

	if (isArray(animation)) {
		return animation;
	}

	if (isPlainObject(animation)) {
		return [animation.x, animation.y];
	}

	const coefX = ANIMATION_COEFFS_X[index] * (isUndefined(left) ? 1 : -1);
	const coefY = ANIMATION_COEFFS_Y[index] * (isUndefined(bottom) ? 1 : -1);
	return [coefX, coefY];
}

function elementPercentInView(element) {
	const { top, bottom, height } = element.getBoundingClientRect();
	const viewportHeight =
		window.innerHeight || document.documentElement.clientHeight;

	if (top > viewportHeight) {
		return 0;
	}
	if (bottom < viewportHeight) {
		return 1;
	}
	return (viewportHeight - top) / height;
}
