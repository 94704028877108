import Bridge from "../../images/elements/desert/bridge.png";
import BridgeWebp from "../../images/elements/desert/bridge.webp";
import Cactus1 from "../../images/elements/desert/cactus1.png";
import Cactus1Webp from "../../images/elements/desert/cactus1.webp";
import Cactus2 from "../../images/elements/desert/cactus2.png";
import Cactus2Webp from "../../images/elements/desert/cactus2.webp";
import Desert from "../../images/elements/desert/desert.png";
import DesertWebp from "../../images/elements/desert/desert.webp";
import DesertXs from "../../images/elements/desert/desert-xs.png";
import DesertXsWebp from "../../images/elements/desert/desert-xs.webp";
import Observatory from "../../images/elements/desert/observatory.png";
import ObservatoryWebp from "../../images/elements/desert/observatory.webp";
import Sun from "../../images/elements/desert/sun.svg";
import Sign from "../../images/elements/desert/sign.png";
import SignWebp from "../../images/elements/desert/sign.webp";
import Shape1 from "../../images/elements/desert/shape1.svg";
import Shape2 from "../../images/elements/desert/shape2.svg";
import Sky from "../../images/elements/common/sky.png";
import SkyWebp from "../../images/elements/common/sky.webp";
import Collage from "../Collage";

import * as React from "react";

const DesertCollage = ({ className }) => (
    <Collage
        className={className}
        fullWidth
        minWidth={800}
        zIndex={0}
        items={[
            {
                src: Sky,
                srcWebp: SkyWebp,
                alt: "Sky",
                height: "100%",
                width: "100%",
                cover: true,
                top: -10,
                animation: { x: 0.1, y: -0.2, end: 0.0001 },
            },
            {
                src: Sun,
                alt: "Sun",
                height: "56.6%",
                width: "auto",
                bottom: 31.4,
                right: 9,
                rightXs: 20,
                animation: { x: 0.5, y: -0.3, end: 0.3 },
            },
            {
                src: Observatory,
                srcWebp: ObservatoryWebp,
                alt: "Observatory",
                height: "25%",
                width: "auto",
                bottom: 38.2,
                right: 28.26,
                rightXs: 32,
                animation: { x: 0, y: 0.6, end: 0.1 },
            },
            {
                src: Desert,
                srcWebp: DesertWebp,
                srcXs: DesertXs,
                srcXsWebp: DesertXsWebp,
                alt: "Desert",
                root: true,
                height: "auto",
                width: "100%",
            },
            {
                src: Cactus1,
                srcWebp: Cactus1Webp,
                alt: "Cactus 1",
                height: "74.7%",
                width: "auto",
                bottom: 12,
                left: 19.5,
                leftXs: 30,
            },
            {
                src: Sign,
                srcWebp: SignWebp,
                alt: "Danger Sign",
                height: "31%",
                width: "auto",
                bottom: 8.12,
                left: 10,
                leftXs: 28,
                animation: { x: -0.5, y: 0, end: 0.1 },
            },
            {
                src: Bridge,
                srcWebp: BridgeWebp,
                alt: "Bridge",
                height: "81.3%",
                width: "auto",
                bottom: 0,
                left: 12,
                leftXs: 30,
                animation: { x: -0.2, y: 0.5, end: 0.25 },
            },
            {
                src: Cactus2,
                srcWebp: Cactus2Webp,
                alt: "Cactus 2",
                minViewWidth: 800,
                height: "54.9%",
                width: "auto",
                bottom: 19.3,
                right: 3.95,
                animation: { x: 0.5, y: 0.3, end: 0.4 },
            },
            {
                src: Shape1,
                alt: "Shape 1",
                height: "3.79%",
                width: "auto",
                bottom: 65,
                right: 36.5,
                rightXs: 47,
            },
            {
                src: Shape2,
                alt: "Shape 2",
                height: "2.03%",
                width: "auto",
                bottom: 65.7,
                left: 9.86,
            },
        ]}
    />
);

export default DesertCollage;
