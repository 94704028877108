import Ballerina from "../../images/elements/ballerina/ballerina.png";
import BallerinaWebp from "../../images/elements/ballerina/ballerina.webp";
import BallerinaXs from "../../images/elements/ballerina/ballerina-xs.png";
import BallerinaXsWebp from "../../images/elements/ballerina/ballerina-xs.webp";
import Circle from "../../images/elements/ballerina/circle.svg";
import Curtain from "../../images/elements/ballerina/curtain.png";
import CurtainWebp from "../../images/elements/ballerina/curtain.webp";
import Key from "../../images/elements/ballerina/key.png";
import KeyWebp from "../../images/elements/ballerina/key.webp";
import Laptop from "../../images/elements/ballerina/laptop.png";
import LaptopWebp from "../../images/elements/ballerina/laptop.webp";
import LaptopXs from "../../images/elements/ballerina/laptop-xs.png";
import LaptopXsWebp from "../../images/elements/ballerina/laptop-xs.webp";
import Shape1 from "../../images/elements/ballerina/shape1.svg";
import Shape2 from "../../images/elements/ballerina/shape2.svg";
import Collage from "../Collage";

import * as React from "react";

const BallerinaCollage = ({ className }) => (
    <Collage
        className={className}
        zIndex={0}
        maxWidth="90%"
        // height="auto"
        // width="max-content"
        items={[
            {
                src: 1.466,
                root: true,
                width: 511,
            },
            {
                src: Curtain,
                srcWebp: CurtainWebp,
                alt: "Curtain",
                height: "auto",
                width: "52.59%",
                top: 5.3,
                right: 14.24,
            },
            {
                src: Circle,
                alt: "Circle",
                height: "auto",
                width: "31.99%",
                top: 19.08,
                left: 19.56,
            },
            {
                src: Key,
                srcWebp: KeyWebp,
                alt: "Key",
                height: "auto",
                width: "30.36%",
                top: 40.85,
                left: 4.39,
            },
            {
                src: Laptop,
                srcWebp: LaptopWebp,
                srcXs: LaptopXs,
                srcXsWebp: LaptopXsWebp,
                alt: "Laptop",
                height: "auto",
                width: "100%",
                bottom: 0,
                left: 0,
                animation: [0, -0.4],
            },
            {
                src: Ballerina,
                srcWebp: BallerinaWebp,
                srcXs: BallerinaXs,
                srcXsWebp: BallerinaXsWebp,
                alt: "Ballerina",
                height: "auto",
                width: "68.4%",
                top: 0,
                right: 1.86,
            },
            {
                src: Shape1,
                alt: "Shape 1",
                height: "auto",
                width: "13.84%",
                top: 28.21,
                left: 11.55,
            },
            {
                src: Shape2,
                alt: "Shape 2",
                height: "auto",
                width: "18.46%",
                bottom: 39.92,
                right: 2.3,
            },
        ]}
    />
);

export default BallerinaCollage;
