import * as styles from "./Button.module.css";

import React from "react";
import classnames from "classnames";

export default function Button({ children, href, target, primary, className }) {
	const props = {
		className: classnames(
			styles.button,
			primary ? styles.primary : null,
			className
		),
	};

	if (href) {
		return (
			<a href={href} target={target} {...props}>
				{children}
			</a>
		);
	}

	return (
		<button type="button" {...props}>
			{children}
		</button>
	);
}
