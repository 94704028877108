import Root from "../../images/elements/space/root.png";
import Ground from "../../images/elements/space/ground.png";
import GroundWebp from "../../images/elements/space/ground.webp";
import GroundXs from "../../images/elements/space/ground-xs.png";
import GroundXsWebp from "../../images/elements/space/ground-xs.webp";
import Man from "../../images/elements/space/man.png";
import ManWebp from "../../images/elements/space/man.webp";
import Woman from "../../images/elements/space/woman.png";
import WomanWebp from "../../images/elements/space/woman.webp";
import Moon from "../../images/elements/space/moon.png";
import MoonWebp from "../../images/elements/space/moon.webp";
import MoonXs from "../../images/elements/space/moon-xs.png";
import MoonXsWebp from "../../images/elements/space/moon-xs.webp";
import Mountains from "../../images/elements/space/mountains.png";
import MountainsWebp from "../../images/elements/space/mountains.webp";
import MountainsXs from "../../images/elements/space/mountains-xs.png";
import MountainsXsWebp from "../../images/elements/space/mountains-xs.webp";
import Planet1 from "../../images/elements/space/planet1.png";
import Planet1Webp from "../../images/elements/space/planet1.webp";
import Planet1Xs from "../../images/elements/space/planet1-xs.png";
import Planet1XsWebp from "../../images/elements/space/planet1-xs.webp";
import Planet2 from "../../images/elements/space/planet2.png";
import Planet2Webp from "../../images/elements/space/planet2.webp";
import Robot from "../../images/elements/space/robot.png";
import RobotWebp from "../../images/elements/space/robot.webp";
import Shape1 from "../../images/elements/space/shape1.svg";
import Shape2 from "../../images/elements/space/shape2.svg";

import Sky from "../../images/elements/common/sky.png";
import SkyWebp from "../../images/elements/common/sky.webp";
import Collage from "../Collage";

import * as React from "react";

const SpaceCollage = ({ className }) => (
    <Collage
        className={className}
        fullWidth
        zIndex={0}
        minWidth={1000}
        items={[
            {
                src: Root,
                alt: "Transparent background",
                root: true,
                height: "auto",
                width: "100%",
            },
            {
                src: Sky,
                srcWebp: SkyWebp,
                alt: "Sky",
                height: "93%",
                width: "100%",
                cover: true,
                top: 0,
            },
            {
                src: Moon,
                srcWebp: MoonWebp,
                srcXs: MoonXs,
                srcXsWebp: MoonXsWebp,
                alt: "Moon",
                height: "69.6%",
                width: "auto",
                right: -4,
                rightXs: 5,
                bottom: 3.41,
            },
            {
                src: Mountains,
                srcWebp: MountainsWebp,
                srcXs: MountainsXs,
                srcXsWebp: MountainsXsWebp,
                alt: "Mountains",
                height: "37.11%",
                width: "auto",
                left: 0,
                bottom: 0,
            },
            {
                src: Ground,
                srcWebp: GroundWebp,
                srcXs: GroundXs,
                srcXsWebp: GroundXsWebp,
                alt: "Ground",
                height: "25.62%",
                width: "auto",
                right: 0,
                rightXs: 10,
                bottom: 0,
            },
            {
                src: Man,
                srcWebp: ManWebp,
                alt: "Man",
                height: "49.5%",
                width: "auto",
                right: 10.13,
                rightXs: 26,
                bottom: 12.87,
            },
            {
                src: Woman,
                srcWebp: WomanWebp,
                alt: "Woman",
                height: "42.57%",
                width: "auto",
                right: 23.75,
                rightXs: 38,
                bottom: 12.93,
            },
            {
                src: Planet1,
                srcWebp: Planet1Webp,
                srcXs: Planet1Xs,
                srcXsWebp: Planet1XsWebp,
                alt: "Yellow Planet",
                height: "38.13%",
                width: "auto",
                left: 26.32,
                leftXs: 35,
                top: 12.42,
                topXs: 8,
                animation: [0.1, -0.2],
            },
            {
                src: Planet2,
                srcWebp: Planet2Webp,
                alt: "Purple Planet",
                height: "20.28%",
                width: "auto",
                left: 4.29,
                leftXs: 34,
                top: 31.96,
            },
            {
                src: Robot,
                srcWebp: RobotWebp,
                alt: "Robot",
                height: "20.91%",
                width: "auto",
                right: 42.43,
                rightXs: 52,
                bottom: 16.13,
            },
            {
                src: Shape1,
                alt: "Shape 1",
                height: "3.74%",
                width: "auto",
                right: 3.69,
                top: 26.18,
            },
            {
                src: Shape2,
                alt: "Shape 2",
                height: "3.74%",
                width: "auto",
                left: 27.78,
                leftXs: 34,
                top: 54.89,
            },
        ]}
    />
);

export default SpaceCollage;
