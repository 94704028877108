// extracted by mini-css-extract-plugin
export var page = "index-module--page--3eaQQ";
export var main = "index-module--main--X-C1v";
export var main__background = "index-module--main__background--i5fbf";
export var main__contents = "index-module--main__contents--1L6pB";
export var main__contents__flex = "index-module--main__contents__flex--9FNt9";
export var main__contents__copy = "index-module--main__contents__copy--zZPne";
export var main__contents__collage = "index-module--main__contents__collage--3BqRB";
export var collage = "index-module--collage--g3Lhi";
export var main__contents__cta = "index-module--main__contents__cta--2RHd-";
export var section = "index-module--section--3tH88";
export var section__index = "index-module--section__index--1Q0wr";
export var tech = "index-module--tech--2YRbj";
export var tech1 = "index-module--tech1--280Aw";
export var tech2 = "index-module--tech2--2pZ42";
export var tech3 = "index-module--tech3--2YSCC";
export var tech4 = "index-module--tech4--3x_2J";
export var section__products = "index-module--section__products--4XHCB";
export var products = "index-module--products--1mb9R";
export var product = "index-module--product--1rUOW";
export var product__image = "index-module--product__image--1uX-V";
export var product__logo = "index-module--product__logo--2bkjh";
export var product__logo__image = "index-module--product__logo__image--22gfP";
export var product__tagline = "index-module--product__tagline--1-eYd";
export var howitworks = "index-module--howitworks--IcOmb";
export var aboutus = "index-module--aboutus--n5ELe";
export var team = "index-module--team--yBDY3";
export var teamMembers = "index-module--teamMembers--2reK0";
export var teamMember = "index-module--teamMember--1GSPt";
export var teamMember__image = "index-module--teamMember__image--1WR3D";
export var teamMember__name = "index-module--teamMember__name--1eYpV";
export var teamMember__title = "index-module--teamMember__title--3a4oT";
export var teamMember__bullets = "index-module--teamMember__bullets--1DDK9";
export var sep = "index-module--sep--zEGxx";