/* eslint-disable react/jsx-no-target-blank, jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */
import * as styles from "./index.module.css";
import Layout from "../layouts/Layout";
import Button from "../components/Button";
import DoorCollage from "../components/collages/DoorCollage";
import DesertCollage from "../components/collages/DesertCollage";
import LondonCollage from "../components/collages/LondonCollage";
import CityCollage from "../components/collages/CityCollage";
import BallerinaCollage from "../components/collages/BallerinaCollage";
import SpaceCollage from "../components/collages/SpaceCollage";

import SkyImage from "../images/sky.png";
import SkyImageWebp from "../images/sky.webp";

import ProductImageStore from "../images/products/adventure-store.png";
import ProductImageStoreWebp from "../images/products/adventure-store.webp";
import ProductImagePap from "../images/products/peek-a-phone.png";
import ProductImagePapWebp from "../images/products/peek-a-phone.webp";
import ProductImagePapLogo from "../images/products/peek-a-phone-logo.png";
import ProductImagePapLogoWebp from "../images/products/peek-a-phone-logo.webp";

// import TeamMemberGilad from "../images/team/gilad.jpg";
// import TeamMemberGiladWebp from "../images/team/gilad.webp";
// import TeamMemberTzach from "../images/team/tzach.jpg";
// import TeamMemberTzachWebp from "../images/team/tzach.webp";
// import TeamMemberStephen from "../images/team/stephen.jpg";
// import TeamMemberStephenWebp from "../images/team/stephen.webp";
// import TeamMemberMaxim from "../images/team/maxim.jpg";
// import TeamMemberMaximWebp from "../images/team/maxim.webp";
// import TeamMemberAnna from "../images/team/anna.jpg";
// import TeamMemberAnnaWebp from "../images/team/anna.webp";

import * as React from "react";
import classnames from "classnames";

export default function IndexPage() {
    return (
        <Layout title="FaintLines - Adventures Starring You">
            <div className={styles.page}>
                <SectionMain />
                <Sep />
                <a id="technology" />
                <h2 className="h3">{"Technology & Platform"}</h2>
                <Sep />
                <SectionTech1 />
                <Sep />
                <SectionTech2 />
                <Sep />
                <SectionTech3 />
                <Sep />
                <SectionTech4 />
                <Sep />
                <SectionTech5 />
                <Sep />
                <a id="products" />
                <SectionProducts />
                {/* <Sep /> */}
                {/* <SectionHowItWorks /> */}
                {/* <Sep /> */}
                {/* <SectionAboutUs /> */}
                {/* <Sep /> */}
                {/* <a id="team" /> */}
                {/* <SectionTeam /> */}
            </div>
        </Layout>
    );
}

const SectionMain = () => (
    <section className={styles.main}>
        <picture>
            <source srcSet={SkyImageWebp} type="image/webp" />
            <img
                className={styles.main__background}
                src={SkyImage}
                alt="sky background"
            />
        </picture>
        <div className={styles.main__contents}>
            <h1>{"Adventures Starring You"}</h1>
            <div className={styles.main__contents__flex}>
                <div className={styles.main__contents__copy}>
                    <p>
                        {
                            "When the power of AI meets the vision of great creators, we have a whole new way to experience stories. They become adventures."
                        }
                    </p>
                    <Button
                        href="mailto:request-demo@faintlines.com"
                        target="_blank"
                        className={styles.main__contents__cta}
                        primary
                    >
                        {"Request a demo"}
                    </Button>
                </div>
                <div className={styles.main__contents__collage}>
                    <DoorCollage className={styles.collage} />
                </div>
            </div>
        </div>
    </section>
);

const SectionTech1 = () => (
    <SectionTech name="tech1" index="01" title="Bring Your Story to Life">
        <p>
            {
                "Make your story a reality by creating an adventure for anytime, anywhere. The FaintLines platform is a one-stop shop with all the tools you need to develop your characters, shape their personalities, and adjust their interactivity. From there, you can deploy, manage, and monetize your storyworld. Our beta testers will offer invaluable real-time feedback."
            }
        </p>
        <DesertCollage className={styles.collage} />
    </SectionTech>
);

const SectionTech2 = () => (
    <SectionTech name="tech2" index="02" title="Meet Your Characters">
        <p>
            {
                "Imagine Sherlock Holmes reaching out over FaceTime. What would you say? How would Sherlock respond? Let our cutting-edge AI role-play your characters based on your preferences. Our technology lets players interact with characters the same way they would real people—using free text and via social media, text, and video chat."
            }
        </p>
        <LondonCollage className={styles.collage} />
    </SectionTech>
);

const SectionTech3 = () => (
    <SectionTech name="tech3" index="03" title="Let AI Tailor Your Story">
        <p>
            {
                "Our powerful AI will adapt your story elements to each player's preferences. Your timelines, settings, and even your characters' personalities are curated to the player, their location, and the time of day or season. The end result is an experience so thrilling—and so immersive—that players often feel it was created just for them."
            }
        </p>
        <CityCollage className={styles.collage} />
    </SectionTech>
);

const SectionTech4 = () => (
    <SectionTech
        name="tech4"
        index="04"
        title="Add Places, Live Actors and More"
    >
        <p>
            {
                "Take advantage of our library of real-world services and websites, which includes social media, news, streaming services, and many more. Instantly create new fictional businesses and websites to complement your storyworld. Use our real-life locations database to bring your story closer to the player. Incorporate live actors, and much more."
            }
        </p>
        <BallerinaCollage className={styles.collage} />
    </SectionTech>
);

const SectionTech5 = () => (
    <SectionTech name="tech5" index="05" title="Build Multiplayer Adventures">
        <p>
            {
                "What if you could bring two or more players into the same storyworld? How will the actions of one player impact the others? Make design choices that foster connection, collaboration, and even competition among players and let their social interactions create meaningful long-lasting memories."
            }
        </p>
        <SpaceCollage className={styles.collage} />
    </SectionTech>
);

const SectionTech = ({ name, index, title, children }) => (
    <section className={classnames(styles.section, styles.tech, styles[name])}>
        <div className={styles.section__index}>{index}</div>
        <h3 className="h2">{title}</h3>
        {children}
    </section>
);

const SectionProducts = () => (
    <section className={classnames(styles.section, styles.section__products)}>
        <h2>{"Built on Our Platform"}</h2>
        <div className={styles.products}>
            <Product
                name="Adventure Store"
                image={ProductImageStore}
                imageWebp={ProductImageStoreWebp}
                logoText="Adventure Store"
                tagline="Adventures starring you"
                href="https://adventurestore.me"
            />
            <Product
                name="Peek a Phone"
                image={ProductImagePap}
                imageWebp={ProductImagePapWebp}
                logoImage={ProductImagePapLogo}
                logoImageWebp={ProductImagePapLogoWebp}
                tagline="Investigate real-life mysteries"
                href="https://peekaphonegame.com"
            />
        </div>
    </section>
);

const Product = ({
    name,
    href,
    image,
    imageWebp,
    logoText,
    logoImage,
    logoImageWebp,
    tagline,
}) => (
    <a className={styles.product} href={href} target="_blank">
        <picture>
            <source srcSet={imageWebp} type="image/webp" />
            <img
                className={styles.product__image}
                src={image}
                alt={`${name} icon`}
            />
        </picture>
        <h3 className={classnames(styles.product__logo, "h2")}>
            {logoImage ? (
                <picture>
                    <source srcSet={logoImageWebp} type="image/webp" />
                    <img
                        src={logoImage}
                        alt={name}
                        className={styles.product__logo__image}
                    />
                </picture>
            ) : (
                logoText
            )}
        </h3>
        <p className={styles.product__tagline}>{tagline}</p>
    </a>
);

// const SectionHowItWorks = () => (
//     <section className={classnames(styles.section, styles.howitworks)}>
//         <h2>{"How it Works"}</h2>
//         <p>
//             {
//                 "The FaintLines platform makes it super easy to create scalable and repeatable adventures. Create and launch your new adventure today. Our platform will bring you an audience, collect their real-time feedback and suggest improvements."
//             }
//         </p>
//     </section>
// );

// const SectionAboutUs = () => (
//     <section className={classnames(styles.section, styles.aboutus)}>
//         <h2>{"About Us"}</h2>
//         <p>
//             {
//                 "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet."
//             }
//         </p>
//         <p>
//             {
//                 "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet."
//             }
//         </p>
//         <p>
//             {
//                 "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet."
//             }
//         </p>
//     </section>
// );

// const SectionTeam = () => (
//     <section className={classnames(styles.section, styles.team)}>
//         <h2>{"Team"}</h2>
//         <div className={styles.teamMembers}>
//             <TeamMember
//                 name="Gilad Raichstain"
//                 title="Founder & CEO"
//                 image={TeamMemberGilad}
//                 imageWebp={TeamMemberGiladWebp}
//                 bullets={[
//                     "Head of AI Products at Salesforce's Sales Cloud",
//                     "Founder & CEO of Implisit, acquired by Salesforce",
//                     "Intel's youngest employee",
//                     "At 16, started undergrad at Technion; Wharton MBA class of 2011",
//                 ]}
//             />
//             <TeamMember
//                 name="Tzach Yarimi"
//                 title="Founder & CTO"
//                 image={TeamMemberTzach}
//                 imageWebp={TeamMemberTzachWebp}
//                 bullets={[
//                     "Salesforce's youngest Architect",
//                     "Second employee at Implisit, acquired by Salesforce",
//                     "Team leader at the Israeli Prime Minister’s Office",
//                     "At 15, started undergrad at Open University, M.Sc. in Computer Science",
//                 ]}
//             />
//             <TeamMember
//                 name="Stephen Kearin"
//                 title="Creative Advisor"
//                 image={TeamMemberStephen}
//                 imageWebp={TeamMemberStephenWebp}
//                 bullets={[
//                     "Co-creator of EA's The Sims language, Simlish",
//                     "Creative advisor to Disney, DreamWorks, Paramount, Netflix & LAIKA",
//                     "Visiting instructor at Stanford, USC, US Olympic Ski Team & Cirque Du Soleil",
//                 ]}
//             />
//             <TeamMember
//                 name="Maxim Kirilov"
//                 title="Lead Software Dev."
//                 image={TeamMemberMaxim}
//                 imageWebp={TeamMemberMaximWebp}
//             />
//             <TeamMember
//                 name="Anna Volante"
//                 title="Adventure Writer"
//                 image={TeamMemberAnna}
//                 imageWebp={TeamMemberAnnaWebp}
//             />
//         </div>
//     </section>
// );

// const TeamMember = ({ name, title, image, imageWebp, bullets }) => (
//     <div className={styles.teamMember}>
//         <picture>
//             <source srcSet={imageWebp} type="image/webp" />
//             <img className={styles.teamMember__image} src={image} alt="" />
//         </picture>
//         <h3 className={classnames(styles.teamMember__name, "h2")}>{name}</h3>
//         <h4 className={classnames(styles.teamMember__title, "h3")}>{title}</h4>
//         {bullets ? (
//             <ul className={styles.teamMember__bullets}>
//                 {bullets.map((text, i) => (
//                     <li key={i}>{text}</li>
//                 ))}
//             </ul>
//         ) : null}
//     </div>
// );

const Sep = () => <div className={styles.sep} />;
