import Root from "../../images/elements/city/root.png";
import Billboard from "../../images/elements/city/billboard.png";
import BillboardWebp from "../../images/elements/city/billboard.webp";
import Bridge from "../../images/elements/city/bridge.png";
import BridgeWebp from "../../images/elements/city/bridge.webp";
import BridgeXs from "../../images/elements/city/bridge-xs.png";
import BridgeXsWebp from "../../images/elements/city/bridge-xs.webp";
import Building from "../../images/elements/city/building.png";
import BuildingWebp from "../../images/elements/city/building.webp";
import BuildingXs from "../../images/elements/city/building-xs.png";
import BuildingXsWebp from "../../images/elements/city/building-xs.webp";
import Tree from "../../images/elements/city/tree.png";
import TreeWebp from "../../images/elements/city/tree.webp";
import Woman from "../../images/elements/city/woman.png";
import WomanWebp from "../../images/elements/city/woman.webp";
import WomanXs from "../../images/elements/city/woman-xs.png";
import WomanXsWebp from "../../images/elements/city/woman-xs.webp";
import Rect1 from "../../images/elements/city/rect1.svg";
import Rect2 from "../../images/elements/city/rect2.svg";
import Rect3 from "../../images/elements/city/rect3.svg";
import Rect4 from "../../images/elements/city/rect4.svg";
import Rect5 from "../../images/elements/city/rect5.svg";
import Light from "../../images/elements/city/light.svg";
import Plane from "../../images/elements/city/plane.png";
import PlaneWebp from "../../images/elements/city/plane.webp";
import Collage from "../Collage";
import { unicodeToCodepoint } from "../../utils";

import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { countryCodeEmoji } from "country-code-emoji";

const CityCollage = ({ className }) => (
    <Collage
        className={className}
        fullWidth
        minWidth={800}
        zIndex={0}
        items={[
            {
                src: Root,
                alt: "Transparent background",
                root: true,
                height: "auto",
                width: "100%",
            },
            {
                src: Rect1,
                alt: "Rectangle 1",
                height: "52.3%",
                width: "auto",
                bottom: 28.7,
                left: 27.3,
            },
            {
                src: Building,
                srcWebp: BuildingWebp,
                srcXs: BuildingXs,
                srcXsWebp: BuildingXsWebp,
                alt: "Building",
                height: "72.5%",
                width: "auto",
                bottom: 28.7,
                right: 32.5,
            },
            {
                src: Bridge,
                srcWebp: BridgeWebp,
                srcXs: BridgeXs,
                srcXsWebp: BridgeXsWebp,
                alt: "Bridge",
                height: "100%",
                width: "auto",
                bottom: 0,
                left: 0,
                leftXs: 15,
            },
            {
                src: Tree,
                srcWebp: TreeWebp,
                alt: "Tree",
                minViewWidth: 801,
                height: "28.1%",
                width: "auto",
                bottom: 28.75,
                left: 25.1,
            },
            {
                src: Rect2,
                alt: "Rectangle 2",
                height: "2.13%",
                width: "auto",
                bottom: 65,
                left: 30.8,
            },
            {
                src: Billboard,
                srcWebp: BillboardWebp,
                alt: "Billboard",
                height: "15.6%",
                width: "auto",
                bottom: 77,
                left: 47.4,
            },
            {
                src: Woman,
                srcWebp: WomanWebp,
                srcXs: WomanXs,
                srcXsWebp: WomanXsWebp,
                alt: "Woman looking at her phone",
                height: "91.3%",
                width: "auto",
                bottom: 7.76,
                right: 7,
            },
            {
                src: Light,
                alt: "Screen light",
                height: "32.66%",
                width: "auto",
                bottom: 46.6,
                right: 34.43,
            },
            {
                src: Time,
                height: "50px",
                width: "9.17%",
                top: 9.6,
                right: 43,
            },
            {
                src: Rect3,
                alt: "Rectangle 3",
                height: "7.77%",
                width: "auto",
                bottom: 0,
                right: 10.7,
            },
            {
                src: Rect4,
                alt: "Rectangle 4",
                height: "5.18%",
                width: "auto",
                bottom: 13.4,
                left: 40,
            },
            {
                src: Rect5,
                alt: "Rectangle 5",
                height: "2.58%",
                width: "auto",
                bottom: 59.52,
                left: 40,
            },
            {
                src: Plane,
                alt: "Airplane",
                srcWebp: PlaneWebp,
                height: "18.6%",
                width: "auto",
                top: 5,
                right: 5.8,
            },
            {
                src: Flag,
                height: "1.5%",
                width: "auto",
                top: 14,
                right: 14.25,
            },
        ]}
    />
);

const Time = ({ className, style }) => {
    const [time, setTime] = useState(dayjs());
    const [fontSize, setFontSize] = useState(46);
    const root = useRef();

    useEffect(() => {
        const interval = setInterval(() => setTime(dayjs()), 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const resizeHandler = () => {
            if (root.current) {
                setFontSize(root.current.clientWidth / 3);
            }
        };
        resizeHandler();
        window.addEventListener("resize", resizeHandler);
        return () => window.removeEventListener("resize", resizeHandler);
    }, []);

    const updatedStyle = {
        ...style,
        fontSize,
        lineHeight: 1,
        fontWeight: 500,
        textAlign: "center",
    };

    return (
        <div className={className} style={updatedStyle} ref={root}>
            {time.format("hh:mm")}
        </div>
    );
};

const Flag = ({ className, style, onLoad }) => {
    const [country, setCountry] = useState(null);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        fetch("https://ipinfo.io/?token=f62a4f9e8cfed2", {
            signal,
        })
            .then((response) => response.json())
            .then((data) => setCountry(data.country))
            .catch(() => {});
        return () => controller.abort();
    }, []);

    const updatedStyle = {
        ...style,
        transform: `${style.transform || ""} rotate(-19.85deg)`,
        fontSize: 6,
        lineHeight: 1,
    };

    if (!country) {
        return null;
    }

    const emoji = countryCodeEmoji(country);
    if (!emoji) {
        return null;
    }

    const codepoint = unicodeToCodepoint(emoji);

    return (
        <img
            src={`https://twemoji.maxcdn.com/v/13.1.0/svg/${codepoint}.svg`}
            alt=""
            className={className}
            style={updatedStyle}
            onLoad={onLoad}
        />
    );
};

export default CityCollage;
