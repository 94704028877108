import Door from "../../images/elements/door.png";
import DoorWebp from "../../images/elements/door.webp";
import Woman from "../../images/elements/woman1.png";
import WomanWebp from "../../images/elements/woman1.webp";
import Collage from "../Collage";

import * as React from "react";

const DoorCollage = ({ className }) => (
    <Collage
        height="max-content"
        width="100%"
        maxWidth="90%"
        animate={false}
        className={className}
        items={[
            {
                src: Door,
                srcWebp: DoorWebp,
                alt: "Door",
                height: "auto",
                width: "100%",
                root: true,
                // animation: [0, 0],
            },
            {
                src: Woman,
                srcWebp: WomanWebp,
                alt: "Woman looking through the door",
                height: "44%",
                width: "auto",
                bottom: 0,
                right: 0,
                // animation: animateWoman,
            },
        ]}
    />
);

// function animateWoman(viewWidth, viewHeight, frac) {
//     console.log(viewWidth, viewHeight, frac);
//     return [0, 0];
// }

export default DoorCollage;
